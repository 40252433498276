import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { AuthTokenService } from '../auth-token/auth-token.service';
import { CurrentUserService } from '../../../users/services/current-user/current-user.service';
import { LoginFormModel } from '../../models/login-form/login-form.model';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  constructor(
    private authService: AuthService,
    private authTokenService: AuthTokenService,
    private currentUserService: CurrentUserService
  ) {}

  public initCurrentUser(): Observable<void> {
    if (!this.authTokenService.isAccessToken()) {
      return of(void 0);
    }

    return this.currentUserService.updateCurrentUser();
  }

  public login(loginFormModel: LoginFormModel): Observable<void> {
    return this.authService.login(loginFormModel).pipe(
      tap((accessToken: string) => {
        this.authTokenService.setAccessToken(accessToken);
      }),
      switchMap(() => this.currentUserService.updateCurrentUser()),
      map(() => void 0)
    );
  }

  public logoutFromApiAndApplication(): Observable<void> {
    const accessToken: string | null = this.authTokenService.getAccessToken();

    if (!accessToken) {
      return this.logoutFromApplication();
    }

    return this.authService.logout().pipe(switchMap(() => this.logoutFromApplication()));
  }

  public logoutFromApplication(): Observable<void> {
    return of(void 0).pipe(
      tap(() => {
        this.authTokenService.removeAccessToken();
        this.currentUserService.clearCurrentUser();
      })
    );
  }
}
