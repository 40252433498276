import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CurrentUserModel } from '../../../users/models/current-user/current-user.model';
import { CurrentUserService } from '../../../users/services/current-user/current-user.service';
import { ModalService } from '../../../modal/services/modal/modal.service';
import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { AuthUserService } from '../../../auth/services/auth-user/auth-user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends SubscriptionStoreComponent implements OnInit {
  public currentUser: CurrentUserModel | null;

  constructor(
    private currentUserService: CurrentUserService,
    private authUserService: AuthUserService,
    private router: Router,
    private modalService: ModalService
  ) {
    super();

    this.currentUser = null;
  }

  public ngOnInit(): void {
    this.initCurrentUser();
  }

  public logoutEvent(): void {
    this.subscription = this.modalService
      .openPopupModal(
        'layout.header.logoutPopup.titleKey',
        'layout.header.logoutPopup.textKey',
        'layout.header.logoutPopup.successButtonLabelKey',
        'layout.header.logoutPopup.cancelButtonLabelKey'
      )
      .subscribe((action: boolean) => {
        if (action) {
          this.logout();
        }
      });
  }

  private logout(): void {
    this.subscription = this.authUserService.logoutFromApiAndApplication().subscribe(() => {
      this.navigateToLoginPage();
    });
  }

  private navigateToLoginPage(): void {
    this.router.navigate(['/auth/login']);
  }

  private initCurrentUser(): void {
    this.subscription = this.currentUserService.getCurrentUser().subscribe((currentUser: CurrentUserModel | null) => {
      this.currentUser = currentUser;
    });
  }
}
