<div class="sidebar" [class.is-closed]="isClosed">
  <div class="sidebar-content">
    <div class="sidebar-header">
      <a class="sidebar-logo" routerLink="/" *ngIf="!isClosed">
        <img alt="" class="logo" src="assets/images/logos/n-color-red-92x40.svg" />
      </a>

      <button class="button-icon gray toggle-button" (click)="toggleSidebar()">
        <span class="icon" [ngClass]="isClosed ? 'icon-double-chevron-right-E30613-20x20' : 'icon-double-chevron-left-E30613-20x20'"></span>
      </button>
    </div>

    <app-sidebar-menu [sidebarItems]="sidebarItems" [isClosed]="isClosed" *ngIf="sidebarItems.length"></app-sidebar-menu>
  </div>
</div>
