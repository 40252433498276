<div class="sidebar-menu" [class.is-closed]="isClosed">
  <button
    class="button"
    routerLinkActive="is-active"
    [routerLink]="item.url"
    [routerLinkActiveOptions]="{ exact: item.exact }"
    *ngFor="let item of sidebarItems"
  >
    <div class="icon-wrapper">
      <span class="icon {{ item.iconClass }}"></span>
    </div>

    <span class="button-label">{{ item.label | translate }}</span>
  </button>
</div>
