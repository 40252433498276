import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiUrlUtil } from '../../../core/utils/api-url/api-url.util';
import { ApiResponseDto } from '../../../core/dtos/api-response/api-response.dto';
import { LoginFormDto } from '../../dtos/login-form/login-form.dto';
import { AccessTokenDto } from '../../dtos/access-token/access-token.dto';
import { CreateNewPasswordDto } from '../../dtos/create-new-password/create-new-password.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  constructor(private httpClient: HttpClient) {}

  public login(loginFormDto: LoginFormDto): Observable<AccessTokenDto> {
    return this.httpClient
      .post<ApiResponseDto<AccessTokenDto>>(ApiUrlUtil.getApiUrl('security/auth-check'), loginFormDto)
      .pipe(map((response: ApiResponseDto<AccessTokenDto>) => response.data));
  }

  public logout(): Observable<void> {
    return this.httpClient.delete<void>(ApiUrlUtil.getApiUrl('security/logout'));
  }

  public resetPassword(email: string): Observable<void> {
    return this.httpClient.post<void>(ApiUrlUtil.getApiUrl('security/reset-password'), { email });
  }

  public createNewPassword(createNewPasswordDto: CreateNewPasswordDto, token: string): Observable<void> {
    return this.httpClient.post<void>(ApiUrlUtil.getApiUrl(`security/confirm-password/${token}`), createNewPasswordDto);
  }
}
