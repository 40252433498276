import { CreateNewPasswordFormModel } from '../../models/create-new-password-form/create-new-password-form.model';
import { CreateNewPasswordDto } from '../../dtos/create-new-password/create-new-password.dto';

export class CreateNewPasswordDtoCreator {
  public static create(model: CreateNewPasswordFormModel): CreateNewPasswordDto {
    return {
      password: model.password,
      repeat_password: model.repeatPassword,
    };
  }
}
