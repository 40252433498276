import { Injectable } from '@angular/core';

import { LocalStorageService } from '../../../shared/services/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenService {
  private readonly accessTokenKey: string;

  constructor(private localStorageService: LocalStorageService) {
    this.accessTokenKey = 'accessToken';
  }

  public isAccessToken(): boolean {
    return !!this.getAccessToken();
  }

  public getAccessToken(): string | null {
    return this.localStorageService.getItem(this.accessTokenKey);
  }

  public setAccessToken(accessToken: string): void {
    this.localStorageService.setItem(this.accessTokenKey, accessToken);
  }

  public removeAccessToken(): void {
    this.localStorageService.removeItem(this.accessTokenKey);
  }
}
