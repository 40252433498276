import { Component, Input } from '@angular/core';

import { SidebarItemModel } from '../../models/sidebar-item/sidebar-item.model';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent {
  @Input() public sidebarItems!: Array<SidebarItemModel>;
  @Input() public isClosed!: boolean;
}
