import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthHttpService } from '../auth-http/auth-http.service';
import { LoginFormModel } from '../../models/login-form/login-form.model';
import { CreateNewPasswordFormModel } from '../../models/create-new-password-form/create-new-password-form.model';
import { LoginFormDto } from '../../dtos/login-form/login-form.dto';
import { AccessTokenDto } from '../../dtos/access-token/access-token.dto';
import { CreateNewPasswordDto } from '../../dtos/create-new-password/create-new-password.dto';
import { LoginFormDtoCreator } from '../../creators/login-form-dto/login-form-dto.creator';
import { CreateNewPasswordDtoCreator } from '../../creators/create-new-password/create-new-password.creator';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private authHttpService: AuthHttpService) {}

  public login(loginForm: LoginFormModel): Observable<string> {
    const loginFormDto: LoginFormDto = LoginFormDtoCreator.create(loginForm);

    return this.authHttpService.login(loginFormDto).pipe(map((dto: AccessTokenDto) => dto.token));
  }

  public logout(): Observable<void> {
    return this.authHttpService.logout();
  }

  public resetPassword(email: string): Observable<void> {
    return this.authHttpService.resetPassword(email);
  }

  public createNewPassword(createNewPassword: CreateNewPasswordFormModel, token: string): Observable<void> {
    const createNewPasswordDto: CreateNewPasswordDto = CreateNewPasswordDtoCreator.create(createNewPassword);

    return this.authHttpService.createNewPassword(createNewPasswordDto, token);
  }
}
