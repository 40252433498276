import { Injectable } from '@angular/core';

import { SidebarItemModel } from '../../models/sidebar-item/sidebar-item.model';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  public getSidebarItems(): Array<SidebarItemModel> {
    return [
      {
        url: 'workshops',
        label: 'layout.sidebar.menu.workshops',
        iconClass: 'icon-workshop-7D7B7B-24x24',
        exact: false,
      },
      {
        url: 'users',
        label: 'layout.sidebar.menu.users',
        iconClass: 'icon-users-7D7B7B-24x24',
        exact: false,
      },
      {
        url: 'role-management',
        label: 'layout.sidebar.menu.roleManagement',
        iconClass: 'icon-user-7D7B7B-24x24 ',
        exact: false,
      },
      {
        url: 'news',
        label: 'layout.sidebar.menu.news',
        iconClass: 'icon-news-7D7B7B-24x24',
        exact: false,
      },
      {
        url: 'changelog',
        label: 'layout.sidebar.menu.changelog',
        iconClass: 'icon-comment-7D7B7B-24x24',
        exact: false,
      },
      {
        url: 'repair-report',
        label: 'layout.sidebar.menu.repairReport',
        iconClass: 'icon-bar-chart-7D7B7B-24x24 ',
        exact: false,
      },
      {
        url: 'material-consumption-report',
        label: 'layout.sidebar.menu.materialConsumptionReport',
        iconClass: 'icon-bar-chart-7D7B7B-24x24 ',
        exact: false,
      },
    ];
  }
}
