import { LoginFormModel } from '../../models/login-form/login-form.model';
import { LoginFormDto } from '../../dtos/login-form/login-form.dto';

export class LoginFormDtoCreator {
  public static create(model: LoginFormModel): LoginFormDto {
    return {
      username: model.email,
      password: model.password,
      remember_me: model.rememberMe,
    };
  }
}
