<div class="page-layout-main">
  <app-sidebar></app-sidebar>

  <div class="layout-content-wrapper">
    <app-header></app-header>

    <div class="layout-content">
      <ng-scrollbar class="page-layout-main-scrollbar" [viewClass]="'page-layout-main-viewport'">
        <div class="page-content">
          <ng-content select="[page-content]"></ng-content>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</div>
